/**
 * The Tailwind styles for your MFE when running in standalone mode (when
 * running directly in the browser, not through module federation.) We bring
 * along the base styles in this case as your MFE is it's own host.
 */

/**
 * Inject Tailwind's base styles, preflight, etc. and any base styles registered
 * by plugins.
 */
@tailwind base;
/**
 * Inject Tailwind's component classes used by your MFE and any component
 * classes registered by plugins.
 */
@tailwind components;
/**
 * Inject Tailwind's utility classes used by your MFE and any utility classes
 * registered by plugins.
 */
@tailwind utilities;

/**
 * Reset and inherit intuitive box-sizing
 */
html {
  box-sizing: border-box;
  font-size: 16px;

  /* these values will render until the profile theme loads */
  --lt-color-primary: #e0e2d9;
  --lt-color-secondary: #e0e2d9;
  --lt-color-background: #fff;
  --lt-color-on-primary: #000;
  --lt-color-on-primary-alpha: #000;
  --lt-color-on-secondary: #000;
  --lt-color-on-background: #000;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/**
 * Make application full height pre-tailwind classes
 */
html,
body,
.tree {
  min-height: calc(100vh);
  background: var(--lt-color-background);
}

@media (min-width: 767px) {
  html,
  body,
  .tree {
    min-height: calc(100vh - 4.5rem);
  }
}

/* Rainbow ball gradient for the editor */
/* TODO: set up css modules and move this into there and have it component scoped */
.conical-rainbow-gradient {
  background-image: conic-gradient(
    from 0deg at 50% 50% in oklch longer hue,
    oklch(70% 0.3 0) 0%,
    oklch(70% 0.3 0) 100%
  );
  background-image: conic-gradient(
    from 90deg,
    #00c600,
    #00c9e7,
    #3e84ff,
    #cd4eff,
    #ff00bf,
    #ff0024,
    #ff4b00,
    #00c600
  );
}

:root {
  --lt-header-height: 4.5rem;
  --lt-treetop-width: 32.5rem;
  --lt-collection-width: 28rem;
  --lt-side-sheet-width: 26.5rem;
  --lt-text-2xl: 1.5rem;
  --lt-text-xl: 1.25rem;
  --lt-text-lg: 1.125rem;
  --lt-text-md: 1rem;
  --lt-text-sm: 0.875rem;
  --lt-text-xs: 0.75rem;
  --lt-text-xs: 0.75rem;
}

/*
Progressive enhancement to add text-wrap-balance where supported
This will likely be in the next version of tailwind and we can remove this
 */
.lt-text-wrap-balance {
  text-wrap: balance;
}

/*
Onetrust banner styles
TODO: Colocate styles with the component code
*/
#onetrust-consent-sdk #onetrust-banner-sdk.otFlat {
  bottom: 1rem !important;
  top: auto !important;
  left: 1rem !important;
  right: 1rem !important;
  background: rgba(255, 255, 255, 0.6) !important;
  backdrop-filter: blur(8px) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 0 !important;
  border-radius: var(--lt-stack-container-radius) !important;
  color: white !important;
  box-shadow: none !important;
  outline: none !important;
  max-width: 100% !important;
}

#onetrust-consent-sdk #onetrust-banner-sdk.otFlat > div {
  color: var(--lt-color15) !important;
}

#onetrust-consent-sdk #onetrust-banner-sdk.otFlat .ot-sdk-row {
  color: var(--lt-color15) !important;
  display: flex !important;
  flex-direction: column !important;
}

#onetrust-policy {
  padding: 0 0 1rem 0 !important;
  margin: 0 !important;
}

#onetrust-policy-text {
  margin: 0 !important;
}

#onetrust-consent-sdk #onetrust-banner-sdk.otFlat #onetrust-policy-text,
#onetrust-consent-sdk #onetrust-banner-sdk.otFlat #onetrust-policy-text a {
  color: var(--lt-color15) !important;
}

div.ot-sdk-container {
  padding: 1rem 1rem 0.5rem 1rem !important;
}

#onetrust-consent-sdk #onetrust-banner-sdk.otFlat #onetrust-policy-text a {
  font-weight: 600;
}

#onetrust-consent-sdk #onetrust-pc-btn-handler,
#onetrust-reject-all-handler,
#onetrust-accept-btn-handler,
#onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link {
  border-color: none !important;
  padding: 1rem !important;
  text-decoration: none !important;
  border-radius: var(--lt-stack-container-radius) !important;
  margin: 0 0 0.5rem 0 !important;
  outline: none !important;
  border: none !important;
  background-color: var(--lt-color9) !important;
  color: var(--lt-color0) !important;
  transition: all 50ms ease-out;
}

#onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link {
  background-color: transparent !important;
  color: var(--lt-color9) !important;
  text-decoration: underline !important;
}

#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group {
  margin: unset !important;
  transition: all 175ms ease-out !important;
}

#onetrust-button-group-parent {
  display: flex !important;
  justify-content: flex-end !important;
}

#ot-sdk-btn-floating {
  display: none !important;
}

/*
TODO: Move to CSS modules
Spacer element to make the carousel look smooth in the editor
*/
.lt-edit-carousel-spacer {
  width: 0;
}

.editing .lt-edit-carousel-spacer {
  width: 0.5rem;
}

/* globally turn off default webkit tap highlight */
button {
  -webkit-tap-highlight-color: transparent;
}

/* cursor selection to match user theme */
::selection {
  background: var(--lt-color-on-primary);
  color: var(--lt-color-primary);
}
.bg-white ::selection {
  background: var(--lt-color-primary);
  color: var(--lt-color-on-primary);
}

/* TODO: move this to tailwind.config if possible */
.font-extrabold,
.font-semibold,
.font-bold {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 426px) {
  #onetrust-button-group {
    width: 100% !important;
  }

  #onetrust-button-group button {
    text-align: center !important;
  }

  #onetrust-policy-text {
    padding-right: 1rem !important;
  }

  #onetrust-consent-sdk #onetrust-banner-sdk.otFlat .ot-sdk-row {
    color: var(--lt-color15) !important;
    display: flex !important;
    flex-direction: row !important;
  }
}

@media (min-width: 400px) {
  #onetrust-banner-sdk .ot-sdk-container {
    width: 100% !important;
  }

  #onetrust-button-group {
    display: flex !important;
    flex-wrap: wrap !important;
    flex-direction: column !important;
  }

  #onetrust-banner-sdk:not(.ot-iab-2).vertical-align-content #onetrust-button-group-parent,
  #onetrust-banner-sdk:not(.ot-iab-2).vertical-align-content #onetrust-button-group {
    width: 100% !important;
    position: relative !important;
    top: auto !important;
    bottom: auto !important;
    left: auto !important;
    right: auto !important;
    transform: none !important;
    padding: 0 !important;
  }
}

@media (min-width: 1280px) {
  #onetrust-banner-sdk:not(.ot-iab-2).vertical-align-content #onetrust-button-group-parent {
    position: absolute !important;
    left: auto !important;
    right: 1rem !important;
    padding: 0 !important;
    width: auto !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }

  #onetrust-button-group {
    display: flex !important;
    flex-wrap: nowrap !important;
    flex-direction: row !important;
  }

  #onetrust-button-group > button {
    float: unset !important;
  }

  #onetrust-consent-sdk #onetrust-banner-sdk.otFlat #onetrust-button-group > button {
    margin-right: 0.5rem !important;
    margin-bottom: 0 !important;
  }

  #onetrust-button-group-parent {
    width: auto !important;
  }
}

@font-face {
  font-family: 'Link Sans';
  src: url('../assets/fonts/LinkSans-LinkSansVF.woff2') format('woff2-variations');
  font-weight: 200 900;
  font-display: swap;
}
